<style>
.el-icon-info{color:#999; font-size:32px; margin-right:5px;}
.line{display:flex; flex-wrap: nowrap;}

.keyword{width:200px;}
.red{color:red;}
.block td{background-color:#e6e6e6 !important}
.right{text-align: right;}
.el-checkbox--medium{padding:5px 10px !important; height: 31px !important}
.adminedit .adminedit{height: auto; margin: 15vh auto !important;}
.adminedit .label{font-size:15px; color:#909399;}
.adminedit .label:nth-of-type(n+2){margin-top:20px;}

.adminedit .role{width:100%; border-bottom:dashed 1px #ddd;}
.adminedit .role:nth-of-type(n+2){margin-top:10px; }
.adminedit .role_name{color:#666; font-size:14px;}
.adminedit .role_area{font-size:14px;}
.adminedit .el-icon-delete{color:#890720; cursor:pointer; font-size:18px;}

.adminedit .el-checkbox{margin-right:0; width:25%;}
.adminedit .el-autocomplete{width:100%;}
.admin .role_dialog{ margin-top:20vh !important;}
</style>
<template>
    <div class="component admin">
        <!-- 编辑框 -->
        <el-dialog width="400px" custom-class="adminedit" :title="data_for_edit.id>0 ? '编辑管理员' : '添加管理员'" v-model="show_edit" :close-on-click-modal="false">
            <el-row class="label">
                <el-col :span="6" class="red">登录账号</el-col>
                <el-col :span="18">
                    <el-input v-model="data_for_edit.username" placeholder="用于管理登录后台，3-20位" autocomplete="off" size="small" clearable></el-input>
                </el-col>
            </el-row>
            <el-row class="label">
                <el-col :span="6"  :class="{ red : data_for_edit.id == 0 }">登录密码</el-col>
                <el-col :span="18">
                    <el-input v-model="data_for_edit.passwd" :placeholder="data_for_edit.id > 0 ? '6位以上，若不修改请留空' : '6位以上'" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <el-row class="label">
                <el-col :span="6" class="red">显示称呼</el-col>
                <el-col :span="18">
                    <el-input v-model="data_for_edit.truename" placeholder="例如：姓名、机构名" autocomplete="off" size="small" clearable></el-input>
                </el-col>
            </el-row>
            <el-row class="label">
                <el-col :span="6">联系电话</el-col>
                <el-col :span="18">
                    <el-input v-model="data_for_edit.phone" placeholder="输入联系电话" autocomplete="off"  size="small"></el-input>
                </el-col>
            </el-row>
            <el-row class="label">
                <el-col :span="6" class="red">管理角色</el-col>
                <el-col :span="18">
                    <el-select style="width:100%;" v-model="data_for_edit.role" placeholder="请选择角色" size="small">
                        <el-option v-for="(val,key,idx) in can_use_role" :key="idx" :label="val" :value="key"></el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row class="label" v-if="data_for_edit.role=='1'">
                <el-col :span="6" class="red">服务小区</el-col>
                <el-col :span="18">
                    <el-select style="width:100%;" v-model="data_for_edit.eids" multiple collapse-tags placeholder="请选择服务小区" size="small">
                        <el-option v-for="(item,idx) in estates" :key="idx" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-col>
            </el-row>
            <el-row class="label" v-if="['2','3'].includes(data_for_edit.role)">
                <el-col :span="6" class="red">管辖区域</el-col>
                <el-col :span="18">
                    <el-cascader placeholder="请选择权限节点" style="width:100%;" v-model="data_for_edit.area_path" :props="area_props" clearable size="small" ref="area" @change="this.$refs.area.popperVisible = false"></el-cascader>
                </el-col>
            </el-row>
            <template #footer>
                <el-button type="primary" @click="click_edit_done">确 定</el-button>
                <el-button @click="show_edit = false">关 闭</el-button>
            </template>
        </el-dialog>
        <!-- 编辑框结束 -->

        <div class="title-panel">
            <el-row>
                <el-col :span="18">
                    <i class="el-icon-info"></i>
                    <div class="inline"><div class="label">后台账号</div><div class="descript">配置后台账号，包括 各级管理员、 物业账号</div></div>
                </el-col>
                <el-col :span="6" class="btn-col">
                    <el-button type="success" plain icon="el-icon-circle-plus-outline" @click="click_add">添加账号</el-button>
                    <el-button type="primary" plain icon="el-icon-refresh" @click="load_list">刷新数据</el-button>
                </el-col>
            </el-row>
        </div>
        <!-- 筛选条 -->
        <el-row style="display:flex; align-items: center; background: #f9f9f9; padding:5px 5px;">
            <el-select style="width:150px;" v-model="role" placeholder="筛选角色" clearable size="small">
                <el-option v-for="(val,key,idx) in roles" :key="idx" :label="val" :value="key"></el-option>
            </el-select>
            <div>&nbsp;</div>
            <el-cascader style="width:200px;" v-model="area_path" :props="area_props" clearable size="small" ref="area1" @change="this.$refs.area1.popperVisible=false" placeholder="权限区域"></el-cascader>
            <div>&nbsp;</div>
            <el-select  style="width:100px;" class="role" v-model="block" placeholder="账号状态" clearable size="small">
                <el-option label="正常" value="0"></el-option>
                <el-option label="停用" value="1"></el-option>
            </el-select>
            <div>&nbsp;</div>
            <el-input style="width:250px" class="keyword" size="small" v-model="key" placeholder="搜索账号、联系电话" clearable></el-input> 
            <div style="flex-grow:1">&nbsp;</div>
            <el-pagination background layout="total, prev, pager, next" :page-size="size" :current-page="page" :total="total" @current-change="page_change"></el-pagination>
        </el-row>
        <!-- 筛选条结束 -->
        <div class="table-box" id="table-box">
            <el-table :max-height="table_height" :data="list" :stripe="true" border :row-class-name="tableRowClassName">
                <el-table-column label="创建时间" width="180" align="center">
                    <template #default="scope">
                        <span v-html="format_timestamp(scope.row.created_at, true)"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="username" label="登录账号" width="150" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="truename" label="显示称呼" width="150" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column label="角色" width="120" align="center" >
                    <template #default="scope">
                        <span v-html="roles[scope.row.role]"></span>
                    </template>
                </el-table-column>
                <el-table-column label="权限区域 / 小区" width="250" align="center" :show-overflow-tooltip="true">
                    <template #default="scope">
                        <span v-if="scope.row.role>1" v-html="scope.row.area_name ? scope.row.area_name.replace(/,/,' / ') : '-'"></span>
                        <span v-else v-html="scope.row.enames ? scope.row.enames.replace(/,/,'、') : '-'"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="联系电话" width="150" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="lastactive" label="最近活动" width="200" align="center">
                    <template #default="scope">
                        <span v-html="format_timestamp(scope.row.lastactive, true)"></span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" min-width="150" label="操作">
                    <template #default="scope" v-if="role <= gd.me.role">
                        <el-button v-if="scope.row.block==0" @click="click_block(scope.$index)" type="text" size="small">停用</el-button>
                        <el-button v-else @click="click_block(scope.$index)" type="text" size="small">启用</el-button>
                        <el-button @click="click_edit(scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="click_del(scope.$index)" type="text" size="small">删除</el-button>
                        
                    </template>
                </el-table-column>
            </el-table>      
        </div>
    </div>
    
</template>
<script>
import {inject} from 'vue'
import format_timestamp from "../plugins/utils.js"

export default {
    name:'busis',
    setup:function(){
        const gd = inject('gd');
        return {gd};
    },//setup
    data:function(){
        return {
            area_path:'',
            roles:{},
            role:'',
            estates:{},
            eis:'',
            block:'',
            key:'',
            page:1,
            size:10,
            total:0,
            list:[],
            show_edit:false,
            data_for_edit:{},
            table_height:800,
            area_props:{checkStrictly: true, expandTrigger: 'hover', value:'id', label:'name', lazy:true, lazyLoad:this.area_lazyload}
        }
    },//data end
    mounted:function(){
        this.$nextTick(()=>{ this.table_height = document.getElementById('table-box').offsetHeight -12; });
        this.init();
    },//mounted end
    computed:{
        can_use_role:function(){
            let roles = {};
            for(let key in this.roles){
                if(key > 0 && key <= this.gd.me.role){
                    roles[key] = this.roles[key];
                }
            }
            return roles;
        },//can_use_role
    },
    watch:{
        page:function(){ this.load_list(); },//page
        role:function(){ this.page=1; this.load_list(); },//role
        area_path:function(){ this.page=1; this.load_list(); },//area_path
        block:function(){ this.page=1; this.load_list(); },//block
        key:function(){ this.page=1; this.load_list(); },//key
    },
    methods:{
        init: function(){
            this.load_roles();
            this.load_estates();
            this.load_list();
        },//init
        area_lazyload:function(node,resolve){
            this.axios.post("/api/area/list",{id:node.value ? node.value : 0}).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                let data = [];
                ret.data.map(item=>{
                    data.push({id:item.id, name:item.name, leaf:item.hasChildren == 0});
                });
                resolve(data);
            });
        },//area_lazyload
        load_roles:function(){
            this.axios.post("/api/user/roles").then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                let tmp = {};
                ret.data.map(item=>{
                    if(item.key > 0){ tmp[item.key] = item.val; }
                })
                this.roles = tmp;
            });
        },//load_roles
        load_estates:function(){
            this.axios.post("/api/estate/simple_list").then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.estates = ret.data;
            });
        },//load_estates
        load_list:function(){
            this.axios.post("/api/user/admins",{page:this.page, size:this.size, role:this.role, area_path:this.area_path, block:this.block, key:this.key}).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.total = ret.data.total;
                this.list = ret.data.list;
            });
        },//load_list
        tableRowClassName:function({row}){
            return row.block == 1 ? 'block' : '';
        },//tableRowClassName
        format_timestamp:function(stamp){
            return format_timestamp(stamp, true);
        },//format_timestamp
        page_change:function(page){ this.page = page; },//page_change
        click_block:function(idx){
            this.axios.post("/api/user/admin_block",{id:this.list[idx].id}).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.$message.success('操作成功');
                this.list[idx].block = 1 - this.list[idx].block;
            });
        },//click_top
        click_del:function(idx){
            this.$confirm('此操作将永久删除该账号, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.post("/api/user/admin_del",{id:this.list[idx].id}).then(ret=>{
                    if(!ret.ok){this.$message.error(ret.msg); return;}
                    this.$message.success('操作成功');
                    this.list.splice(idx,1);
                }).catch((err) => { console.error(err); });
            }).catch((e) => { console.error(e); });
        },//click_del end
        click_add:function(){
            this.show_edit = true;
            this.data_for_edit = {
                id:0,
                username:'',
                passwd:'',
                truename:'',
                phone:'',
                role:'',
                area_path:'',
                eids:'',
            };
        },//click_add
        click_edit:function(row){
            //console.log(row);
            this.show_edit = true;
            this.data_for_edit = JSON.parse(JSON.stringify(row));
            this.data_for_edit.passwd = '';
            this.data_for_edit.role += '';
            this.data_for_edit.area_path = this.data_for_edit.area_path.split(",").map(item=>{return parseInt(item);});
            this.data_for_edit.eids = this.data_for_edit.eids.split(",").map(item=>{return parseInt(item);});
        },//click_edit end
        click_edit_done:function(){
            if(this.data_for_edit.username.length < 3 || this.data_for_edit.username.length > 20 ){ this.$message.error('请输入管理员账号，3-20位'); return; }
            if(this.data_for_edit.passwd.length > 0 && this.data_for_edit.passwd.length < 6){ this.$message.error('登录密码需6位以上'); return; }
            if(this.data_for_edit.truename == ''){ this.$message.error('请输入显示称呼'); return; }
            if(this.data_for_edit.role == ''){ this.$message.error('请选择用户角色'); return; }
            if(['2','3'].includes(this.data_for_edit.role) && (!this.data_for_edit.area_path || this.data_for_edit.area_path.length < 1)){ this.$message.error("请选择权限区域"); return;}
            if(this.data_for_edit.role == '1' && (!this.data_for_edit.eids || this.data_for_edit.eids.length < 1)){
                this.$message.error("请选择服务小区"); return;
            }

            this.axios.post("/api/user/admin_edit",this.data_for_edit).then(ret=>{
                if(!ret.ok){this.$message.error(ret.msg); return;}
                this.$message.success('操作成功');
                this.show_edit = false;
                this.load_list();
            });
        },//click_edit_done
    },//methods end
};
</script>